import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
import { orderReject, ordermanagementlist, orderexport, profilesingle, uommanagementlist, brandmanagementlist, customermanagementlist, orderConfirm, outstandingDays } from "../../actions";
import { errorRender, fileDownload, loaderUpdate } from '../../helpers/functions';
import Moment from "moment";
import toastr from "reactjs-toastr";
import { confirmAlert } from "react-confirm-alert";
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import "react-confirm-alert/src/react-confirm-alert.css";
import "reactjs-toastr/lib/toast.css";

const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Loader = lazy(() => import('../../components/loader'));
const Transporterdropdown = lazy(() => import("../transporterManagement/transporterDropdwon"));
const SchemeDropDown = lazy(() => import('../schemeManagement/schemeDropdwon'));
// const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = 25;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();
class RetailerOrdermanagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNum: "",
      displayUOM: {},
      companyFormat: "default",
      check: "",
      name: "",
      code: "",
      isLoading: false,
      brand: "",
      brand_filter: "",
      isFilter: false,
      customer: null,
      excelType: "ALL",
      viewOrder: {},
      totQty: 0,
      series: "",
      transporter: null,
      transporterName: null,
      scheme: null,
      agent: "",
      exportLoader: false,
      outstandingDaysOfCustomer: 0,
      startdate: "",
      enddate: "",
      onlyOnceCall: true,
      filterCustomer: { limit: 50000 },
      serverError: ["orderexportresponse", "orderUploadresponse", "orderConfirm_pack"],
      seriesValue: [
        { name: "CLS" },
        // { name: "ACC" },
        // { name: "DTR" },
        // { name: "WHS" },
        // { name: "WHB" },
        // { name: "RMB" },
        // { name: "MID" },
        // { name: "PIN" },
        // { name: "RMG" },
        // { name: "SRC" },
        // { name: "ONL" },
        // { name: "CDTR" },
        // { name: "CWHS" },
        // { name: "CWHB" },
        // { name: "CRMB" },
        // { name: "CMID" },
        // { name: "CPIN" },
        // { name: "CRMG" },
        // { name: "ACS" },
        // { name: "ICS" },
        // { name: "CICS" },
        // { name: "MIX" }
      ],
      mappingObj: {
        true: "Confirmed",
        false: "Pending",
        reject: "Rejected"
      },
      tableHeaders: [
        {
          name: 'companyOrderNum',
          label: "Order No.",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'customer.name',
          label: "Customer",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'agent.name',
          label: "User",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'agent.type',
          label: "User Type",
          type: "text",
          sortable: false,
          isShow: true
        },
        {
          name: 'brand.name',
          label: "Brand",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'totalQuantity',
          label: "Total Quantity",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'totalPaidAmount',
          label: "Total Paid Amount",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'orderStatus',
          label: "Status",
          type: "boolean",
          sortable: true,
          isShow: true
        },
        {
          name: 'createdAt',
          label: "CreatedAt",
          type: "date",
          sortable: true,
          isShow: true
        },
        {
          name: 'actions',
          label: "Actions",
          type: "button",
          sortable: false,
          isShow: true,
          allactions: [
            // {
            //   name: "edit",
            //   title: "Edit",
            //   classNameI: "ti-pencil",
            //   className: "btn btn-link text-pramiry action-btn mr-2 p-0",
            //   function: redirect
            // },
            // {
            //   name: "delete",
            //   title: "Delete",
            //   classNameI: "ti-trash",
            //   className: "btn btn-link text-danger action-btn p-0 mr-2",
            //   function: this.deleteRow
            // },
            {
              classNameI: "ti-eye",
              type: "button",
              className: "btn btn-link text-primary action-btn p-0  ",
              datatoggle: "modal",
              datatarget: ".bd-example-modal-lg",
              dataplacement: "top",
              title: "Order Details",
              dataoriginaltitle: "Click to Order Details",
              name: "edit",
              isPermission: "view",
              function: this.orderDetails
            }
          ]
        }
      ],
      isBackButton: false
    };
  }

  callUOMList = () => {
    let data = {}
    data.filter = { name: '', code: '' }
    this.props.uommanagementlist(data);
  }

  componentDidMount() {
    let data = {};
    this.props.profilesingle(data);
    this.loadResult();
    var params = this.queryStringParse(this.props.location.search);
    if (params && params.dashboard === 'true') {
      // console.log(params);
      this.setState({
        isBackButton: true
      });
    }
    this.callUOMList();
  }

  queryStringParse = function (string) {
    let parsed = {};
    if (string) {
      string = string.substring(string.indexOf('?') + 1)
      let p1 = string.split('&')
      p1.forEach(value => {
        let params = value.split('=')
        parsed[params[0]] = params[1]
      });
    }
    return parsed
  }

  componentWillUnmount() {
    currentPage = CURRENT_PAGE;
    itemsPerPage = ITEM_PER_PAGES;
  }

  loadResult = (flag = false) => {
    this.setState({ isLoading: true, isFilter: false });

    if (this.state.isFilter === true) {
      currentPage = 1;
    };

    let data = {};
    data.wantAgentType = this.state.excelType ? this.state.excelType : "ALL";
    data.requestFrom = "B2C";
    // data.requestFrom = this.state.requestFrom !== "" && this.state.requestFrom !== "ALL" ? this.state.requestFrom : undefined;
    data.filter = {
      name: this.state.name,
      code: this.state.code,
      customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined,
      agent: this.state.agent !== "" ? this.state.agent.value : undefined,
      createdAt: {
        from: this.state.startdate,
        to: this.state.enddate
      },
      brand: this.state.brand_filter
    };

    if (this.state.orderNum) {
      data.filter.companyOrderNum = this.state.orderNum;
      currentPage = 1;
    };

    data.sortBy = sortBy;
    data.sort = sort;
    data.skip = (currentPage - 1) * itemsPerPage;
    data.limit = itemsPerPage;
    this.props.ordermanagementlist(data);

    if (!flag) {
      this.props.brandmanagementlist({
        "filter": {
          "code": ""
        },
        "sortBy": "createdAt",
        "sort": "desc",
        "skip": 0,
        "limit": 50
      });
    };

  }

  ondropdownChange = (e, t) => {
    this.setState({ [e]: t });
  }

  export = () => {

    if (this.state.excelType !== "") {
      this.setState({ exportLoader: true });
      let data = {
        wantAgentType: this.state.excelType,
      };

      data.requestFrom = "B2C";

      // data.requestFrom = this.state.requestFrom !== "" && this.state.requestFrom !== "ALL" ? this.state.requestFrom : undefined;
      data.filter = {
        name: this.state.name,
        code: this.state.code,
        customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined,
        agent: this.state.agent !== "" ? this.state.agent.value : undefined,
        createdAt: {
          from: this.state.startdate,
          to: this.state.enddate
        },
        brand: this.state.brand_filter
      };

      if (this.state.orderNum) {
        data.filter.companyOrderNum = this.state.orderNum;
      };

      data.sortBy = sortBy;
      data.sort = sort;

      // console.log(data);

      this.props.orderexport(data);

    } else {
      toastr.error("Please Select Type  ", "error", {
        displayDuration: 5000,
        width: "400px",
      });
    }
  }

  orderDetails = (ev, item) => {
    const uniqueQuantityUnits = new Set();  
    item.products.forEach(products => {
      products.attributes.forEach(attribute => {
        uniqueQuantityUnits.add(attribute.quantityUnit);
      });
    });
                
    this.setState({
      quantityUnits: Array.from(uniqueQuantityUnits),
      viewOrder: item,
      outstandingDaysOfCustomer: 0,
      series: item.series,

      transporter: item.transporter ? {
        label: item.transporter && item.transporter.name,
        value: item.transporter && item.transporter._id
      } : null,

      scheme: item.scheme ? {
        label: item.scheme && item.scheme.name,
        value: item.scheme && item.scheme._id
      } : null
    });
    this.props.outstandingDays(item._id);
    // this.setState(this.state)
  }

  submit = (item) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.orderConfirm(item),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  orderConfirm = (item) => {
    let data = {
      transportName: this.state.transporter !== null ? this.state.transporter && this.state.transporter.forSelection ? this.state.transporter.forSelection : this.state.transporter.label : undefined,
      schemeName: this.state.scheme && this.state.scheme.label,
      series: this.state.series
    };
    this.props.orderConfirm(data, item._id);
  }

  setdate = (e) => {
    this.setState({
      startdate: Moment(e.target.valueAsDate).format(
        "YYYY-MM-DD"
      )
    });
  }

  sortBy = (key) => {
    sortBy = key;
    if (sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.loadResult();
  }

  resetfilter = () => {
    let data = {};
    data.sortBy = sortBy;
    data.sort = sort;
    data.skip = (currentPage - 1) * itemsPerPage;
    data.limit = itemsPerPage;
    data.requestFrom = "B2C";
    data.wantAgentType = "ALL";
    this.props.ordermanagementlist(data);
    this.setState({
      isFilter: false,
      isLoading: true,
      customer: null,
      startdate: "",
      enddate: "",
      agent: "",
      requestFrom: "B2C",
      brand_filter: "",
      excelType: "ALL"
    });
  }

  toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

  redirectToCreate = () => {
    this.props.history.push(routehelp.order_create);
  };

  handleFile = (event) => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type === "text/csv") {
        file.append("file", event.target.files[0]);
        file.append("brand", this.state.brand);
      } else {
        toastr.error("Please Select only Csv file ", "error", {
          displayDuration: 5000,
          width: "400px",
        });
      }
    }
  };

  onChange = (e) => {
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
    // this.setState({ [e.target.name]: e.target.value })
  };

  handleNext = () => {
    currentPage = currentPage + 1;
    this.loadResult();
  }

  handlePrevious = () => {
    currentPage = currentPage - 1;
    this.loadResult();
  }

  handlePage = (page) => {
    currentPage = page;
    this.loadResult();
  }

  handleChangeiItemsPerPage = (items) => {
    currentPage = 1;
    itemsPerPage = items;
    this.loadResult();
  }

  ondropdownChangeagent = (e, t) => this.setState({ [e]: t });

  // deleteRow = (e, item) => {
  //   if (item.erpOrderCode === undefined || item.erpOrderCode === null) {
  //     if (window.confirm(`Are you sure you wish to delete order "${item.companyOrderNum}" ?`)) {
  //       this.props.delete_order(item._id);
  //       e.preventDefault();
  //     }
  //   }
  // }

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {

      if (nextProps.uommanagement_res && nextProps.uommanagement_res.list && nextProps.uommanagement_res.list.length > 0) {
        let displayUOMList = {};
        nextProps.uommanagement_res.list.forEach((ele) => {
          displayUOMList[ele.code] = ele.name;
        });
        this.setState({
          displayUOM: displayUOMList
        });
      }

      if (nextProps.outstandingDaysResponse !== undefined && nextProps.outstandingDaysResponse.success === true) {
        if (nextProps.outstandingDaysResponse.list && nextProps.outstandingDaysResponse.list.length > 0) {
          this.setState({ outstandingDaysOfCustomer: nextProps.outstandingDaysResponse.list[0].outstandingDays !== null ? nextProps.outstandingDaysResponse.list[0].outstandingDays : 0 })
        }
        nextProps.outstandingDaysResponse.success = null;
      }

      if (nextProps.profile_single && nextProps.profile_single.success === true) {
        // console.log(nextProps.profile_single.item.reportFormat);
        this.setState({
          companyFormat: nextProps.profile_single.item.reportFormat
        }, () => {
          // console.log(this.state.companyFormat);
          this.updateReportFormat();
        });

      }

      if (this.state.isLoading) {
        this.setState({ isLoading: loaderUpdate(nextProps, "ordermanagement_res") });
      }

      if (this.state.exportLoader) this.setState({ exportLoader: false });

      fileDownload(nextProps, "orderexportresponse", "fileName")
      this.state.serverError.forEach((el) => errorRender(nextProps, el));

      if (nextProps.orderConfirm_pack !== undefined && nextProps.orderConfirm_pack.success === true) {

        if (nextProps.orderConfirm_pack.item && nextProps.orderConfirm_pack.item.body && nextProps.orderConfirm_pack.item.body.Status === true) {
          toastr.success("Order Has Been Confirmed", "Success", {
            displayDuration: 5000,
          });
          nextProps.orderConfirm_pack.item.body.Status = null;
          this.loadResult();
        }

        if (nextProps.orderConfirm_pack.item && nextProps.orderConfirm_pack.item.body && nextProps.orderConfirm_pack.item.body.Status === false) {
          toastr.error(nextProps.orderConfirm_pack.item.body.Message, "Error", { displayDuration: 5000 });
          nextProps.orderConfirm_pack.item.body.Status = null;
        }

      }

      if (nextProps.orderConfirm_pack !== undefined && nextProps.orderConfirm_pack.success === false) {
        if (nextProps.orderConfirm_pack && nextProps.orderConfirm_pack.errors && nextProps.orderConfirm_pack.errors.length > 0) {
          nextProps.orderConfirm_pack.errors.forEach((ele) => {
            toastr.error(ele.msg, "Error", { displayDuration: 5000 });
          });
          this.loadResult();
          nextProps.orderConfirm_pack.success = null;
        }
      }

      // if (nextProps.order_delete !== undefined && nextProps.order_delete.success === true) {
      //   nextProps.order_delete.success = null;
      //   toastr.success("Order has been deleted Succesfully", "Success", { displayDuration: 5000 });
      //   this.loadResult();
      // };

      if (nextProps.order_reject && nextProps.order_reject.success && nextProps.order_reject.flag) {
        toastr.success("Order Has Been Rejected", "Success", {
          displayDuration: 5000,
        });
        this.loadResult();
        nextProps.order_reject.flag = null;
      }

      this.state.serverError.forEach((el) => errorRender(nextProps, el));
    }
    return true;
  }


  updateReportFormat = () => {
    if (this.state.companyFormat === "invoice") {
      let newTableHeaders = this.state.tableHeaders || [];
      newTableHeaders.pop();
      newTableHeaders.push({
        name: 'actions',
        label: "Actions",
        type: "invoice",
        sortable: false,
        isShow: true,
        data: [
          {
            target: "_blank",
            type: "href",
            name: "view",
            classNameI: "ti-eye mr-2",
            className: "btn btn-link action-btn",
          },
          {
            type: "button",
            name: "confirm",
            title: "Confirm",
            classNameI: "ti-check",
            className: "btn btn-link text-pramiry action-btn",
            function: this.submit
          },
          {
            type: "button",
            name: "reject",
            title: "Reject",
            classNameI: "ti-close",
            className: "btn btn-link text-danger action-btn",
            function: this.orderRejected
          }]
      });

      this.setState({
        tableHeaders: newTableHeaders
      });

    }
  }

  orderRejected = (data) => {
    confirmAlert({
      title: "Order Reject",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.orderReject(data._id),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  }

  onChangeforDebounce = ({ target: { value } }) => {
    if (!/^\s/.test(value)) {
      this.setState({ orderNum: value }, () => {
        this.setState({ isLoading: true });
        this.callforDebounce(value);
      });
    }
  }

  callforDebounce = () => this.loadResult();

  render() {
    const { ordermanagement_res, brandmanagement_res } = this.props;
    const totResult = ordermanagement_res !== undefined ? ordermanagement_res.count : 0;
    // console.log(ordermanagement_res);
    let { orderNum } = this.state;

    const finalUpdatedRes = ordermanagement_res || {};

    if (this.state.companyFormat === "invoice") {
      if (finalUpdatedRes && finalUpdatedRes.list && finalUpdatedRes.list.length > 0) {
        finalUpdatedRes.list.forEach((ele) => {
          if (ele.isRejected) {
            ele.orderStatus = "reject"
          } else {
            ele.orderStatus = ele.isErpConfirm
          }
        });
      }
    } else {
      if (finalUpdatedRes && finalUpdatedRes.list && finalUpdatedRes.list.length > 0) {
        finalUpdatedRes.list.forEach((ele) => {
          ele.orderStatus = ele.isErpConfirm
        });
      }
    }

    // console.log("this.state.reportFormat" , this.state.companyFormat);
    // console.log("finalUpdatde", finalUpdatedRes);

    // const { orderNum } = this.state;

    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-sm-12 mt-4">

              <div className="card card-shadow mb-4">
                {
                  this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                  </div> : null
                }
                <div className="card-header d-flex">

                  <div className="card-title col-9">Retailer's Order Management</div>


                </div>

                <div className="card-body">
                  <div
                    id="bs4-table_wrapper"
                    className="dataTables_wrapper action-btn-fix container-fluid dt-bootstrap4  p-0"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        {totResult !== undefined ? (
                          <Entries
                            itemsPerPage={itemsPerPage}
                            handleChangeiItemsPerPage={
                              this.handleChangeiItemsPerPage
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div
                          id="bs4-table_filter"
                          className="dataTables_filter  d-flex flex-row justify-content-end align-items-center"
                        >
                          <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={orderNum} />
                          {/* <div className="col-8">
                            <select
                              className="form-control"
                              name="excelType"
                              value={this.state.excelType}
                              onChange={this.onChange}
                              id="excelType"
                            >
                              <option value="">Select a Type</option>
                              <option value="ALL">All</option>
                              <option value="AG">Agent</option>
                              <option value="ASM">Area Sales Manager</option>
                              <option value="ASM2">Area Sales Manager 2</option>
                              <option value="SASM">Sr. AREA SALES MANAGER</option>
                            </select>
                          </div> */}

                          {/* {this.state.exportLoader === true ? <div className="mr-3"> <i className="fa fa-spinner fa-spin fa-2x fa-fw text-primary"> </i> </div> */}
                          {/* : */}

                          <ButtonWithItag
                            disabled={this.state.exportLoader}
                            onclick={this.export}
                            classNameI="fa tai-ico-Export"
                            type="button"
                            className="btn btn-primary mr-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Export as a file"
                            data-original-title="Click to Upload all"
                          />
                          {/* } */}

                          <ButtonWithItag
                            classNameI="ti-filter"
                            onclick={this.toggleFilter}
                            type="button"
                            className="btn btn-primary navbar-toggler right-sidebar-toggler"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Filter"
                            data-original-title="Click for Filter option"
                          />

                          {/* <ButtonWithItag
                            classNameI="ti-plus"
                            onclick={this.redirectToCreate}
                            type="button"
                            className="btn btn-primary navbar-toggler right-sidebar-toggler ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Order create"
                            data-original-title="Click for order creation option"
                          /> */}

                          {/* <ButtonWithItag classNameI="fa tai-ico-Export" type="button" className="btn btn-primary" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="" data-original-title="Click to download all" /> */}
                        </div>
                      </div>
                    </div>

                    {
                      this.state.isLoading ? <Loader /> :
                        (finalUpdatedRes !== undefined && finalUpdatedRes.count === 0) || !finalUpdatedRes ?
                          <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"orderRetailers"} /> :
                          finalUpdatedRes && finalUpdatedRes.count > 0 ?
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="table-responsive">
                                  <DataTable mappingObj={this.state.mappingObj} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={finalUpdatedRes} props={this.props} moduleName={"orderRetailers"} />
                                </div>
                              </div>
                            </div> :
                            <p className="text-center">Something Went Wrong</p>}
                    {
                      totResult !== undefined ? (
                        <Pagination
                          handleChangeiItemsPerPage={
                            this.handleChangeiItemsPerPage
                          }
                          handlePage={this.handlePage}
                          handleNext={this.handleNext}
                          handlePrevious={this.handlePrevious}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          totResult={totResult}
                          key={currentPage}
                        />
                      ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeModalLabel">
                  Order Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-sm-6">
                    {" "}
                    <strong>
                      Logic Order No :{" "}
                    </strong>{" "}  {this.state.viewOrder && this.state.viewOrder.erpOrderCode ? this.state.viewOrder.erpOrderCode : "N/A"}
                    <br />
                    {" "}
                    <strong>
                      Order No :{" "}
                    </strong>{" "}  {this.state.viewOrder && this.state.viewOrder.companyOrderNum}
                    <br />
                    <strong>Customer</strong> <br />
                  </div>
                  <div className="col-sm-6 text-md-right">
                    <div>
                      <span>
                        {this.state.viewOrder
                          ? Moment(this.state.viewOrder.createdAt).format(
                            "DD-MM-YYYY H:mm"
                          )
                          : "N/A"}
                      </span>{" "}
                      <br />
                      {" "}
                    </div>
                    <div className="mt-2">
                      <span>
                        <strong>Outstanding Days</strong>
                      </span>{" "}
                      <br />
                      <span>
                        <b>
                          {this.state.outstandingDaysOfCustomer}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <h6>

                      {
                        this.state.viewOrder &&
                          this.state.viewOrder.customer &&
                          this.state.viewOrder.customer.name ? this.state.viewOrder.customer.name : "N/A"
                      }
                    </h6>
                    {
                      this.state.viewOrder.customer && this.state.viewOrder.customer.createFrom && this.state.viewOrder.customer.createFrom === "mobile" ?
                        <div className="text-bold">
                          <span className="text-success">New Customer</span>&nbsp;(created from mobile application)
                        </div> : null
                    }
                    {
                      this.state.viewOrder && this.state.viewOrder.indirectCustomer && this.state.viewOrder.indirectCustomer ?
                        <div >
                          <strong>Indirect Customer</strong> <br />
                          <span>
                            {this.state.viewOrder && this.state.viewOrder.indirectCustomer && this.state.viewOrder.indirectCustomer.name
                              ? this.state.viewOrder.indirectCustomer.name : null}
                          </span>{" "}
                        </div> : null}
                    <span>
                      <strong>Brand</strong>
                    </span>{" "}
                    <br />
                    <span>
                      <b>
                        {
                          this.state.viewOrder &&
                          this.state.viewOrder.brand &&
                          this.state.viewOrder.brand.name
                        }
                        <b></b>
                      </b>
                    </span>
                    <br />
                  </div>
                  <div className="col-sm-12">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="bg-primary text-white">
                            Design No.
                          </th>
                          <th className="bg-primary text-white" scope="col">
                            Shade
                          </th>
                          {
                              this.state.quantityUnits ?
                                  this.state.quantityUnits.map((item, i) => 
                                  <th className="bg-primary text-white" scope="col" key={i}>{item}</th> 
                                  ) 
                              : null
                          }
                          <th className="bg-primary text-white" scope="col">
                            Qty.
                          </th>
                          <th className="bg-primary text-white" scope="col">
                            Rate
                          </th>
                          <th className="bg-primary text-white" scope="col">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.viewOrder &&
                        this.state.viewOrder.products &&
                        this.state.viewOrder.products.map((item, index) => {
                          // shadequan = 0;
                          // eslint-disable-next-line
                          this.state.totQty = 0;
                          item.attributes.map((attriItem) => {
                            // eslint-disable-next-line
                            return this.state.totQty = this.state.totQty + parseFloat(attriItem.shadeQuantity || 0);
                          });
                          // { console.log(item) }
                          const uniqueShades = [...new Set(item.attributes.map(attr => attr.name))];
                          return uniqueShades.map((shade, i) => {
                            const shadeAttributes = item.attributes.filter(attr => attr.name === shade); 
                          return (
                            <tr key={index + "product"}>
                              {i === 0 && 
                              <td key={index + "qty"} rowSpan={uniqueShades.length}>{item.productName}</td>
                              }                                           
                              <td className='shade-cell' key={index + "shade"}>
                                {shade}   
                              </td>
                              {this.state.quantityUnits.map((unit, j) => {
                                const unitAttribute = shadeAttributes.find(attr => attr.quantityUnit === unit);
                                return (
                                    <td key={j + "unitQty"}>{unitAttribute ? unitAttribute.shadeQuantity === "" ? 0 : unitAttribute.shadeQuantity : 0}</td>
                                  );
                              })}
                              {i === 0 && (
                              <>
                                  <td rowSpan={uniqueShades.length}>{this.state.totQty || 0}</td>
                                  <td rowSpan={uniqueShades.length}>{item.retailerPrice ? item.retailerPrice : item.productPrice}</td>
                                  <td rowSpan={uniqueShades.length}>{item.subTotal}</td>
                              </>
                              )}
                            </tr>
                          );
                        });
                        })
                      }
                        { this.state.quantityUnits && this.state.quantityUnits.length > 0 && 
                          <tr>
                            <td colSpan={2} className='text-right'><b>Total :</b></td>
                              {this.state.quantityUnits.map((unit, unitIndex) => {
                                  let totalQuantity = 0;
                                  this.state.viewOrder.products.forEach((productData) => {
                                      productData.attributes.forEach((item) => {
                                          if (item.quantityUnit === unit) {
                                              totalQuantity += Number(item.shadeQuantity);
                                          }
                                      });
                                  });
                                  return <td key={unitIndex + "totalQuantity"}><b>{totalQuantity}</b></td>;
                              })}
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-4 ml-auto">
                    <table className="table table-bordered table-striped">
                      <tbody>
                        <tr>
                          <td className="bg-primary text-white">
                            <strong>TOTAL</strong>
                          </td>
                          <td className="bg-primary text-white">
                            <strong>
                              {this.state.viewOrder &&
                                this.state.viewOrder.totalAmount}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12 row">
                    <div className=" form-group col-12 col-lg-6">

                      <Transporterdropdown
                        ondropdownChange={(e) => this.ondropdownChangeagent("transporter", e)}
                        placeholder="Search Transporter"
                        value={this.state.transporter}
                      // className="form-control form-control-square"
                      />
                    </div>
                    <div className=" form-group col-12 col-lg-6">
                      <SchemeDropDown
                        ondropdownChange={(e) => this.ondropdownChangeagent("scheme", e)}
                        placeholder="Search Scheme"
                        value={this.state.scheme}
                      />
                    </div>
                    <div className=" form-group col-12 col-lg-6">
                      <label className="control-label">Series</label>
                      <select
                        className="form-control"
                        name="series"
                        value={this.state.series}
                        onChange={this.onChange}
                        id="excelType"
                      >
                        {this.state.seriesValue.map((item, index) => {
                          return <option value={item.name} key={index}>{item.name} </option>
                        })}
                      </select>
                    </div>
                    <div className="form-group col-12 col-lg-6">
                      <label htmlFor="exampleInputName1" className="control-label">Agency</label>
                      <Inputfield disabled={true} type="text" name="agency" value={this.state.viewOrder.agency && this.state.viewOrder.agency.name ? this.state.viewOrder.agency.name : "NIL"} className="form-control" id="agency" ariadescribedby="emailHelp" placeholder="Name" readOnly />
                    </div>
                  </div>
                  {/* <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Payment Terms:</strong>&nbsp;
                      {this.state.viewOrder && this.state.viewOrder.paymentTerms}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Delivery Station:</strong>&nbsp;
                      {this.state.viewOrder && this.state.viewOrder.deliveryStation}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Dispatch Date:</strong>&nbsp;
                      {this.state.viewOrder
                        ? Moment(this.state.viewOrder.dispatchDate).format(
                          "DD-MM-YYYY"
                        )
                        : ""}
                    </div>
                  </div> */}
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Remarks:</strong>&nbsp;
                      {this.state.viewOrder && this.state.viewOrder.note}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Error Message:</strong>&nbsp;
                      {this.state.viewOrder &&
                        this.state.viewOrder.erpOrderMessage}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-white text-center justify-content-center">
                {this.state.viewOrder.isErpConfirm &&
                  this.state.viewOrder.isErpConfirm === true ? (
                  ""
                ) : (
                  <button
                    onClick={() => this.submit(this.state.viewOrder)}
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Confirm
                  </button>
                )}
                <button type="button" className="btn btn-dark" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            !this.state.isFilter
              ? "right-sidebarfilter hidden"
              : "right-sidebarfilter show"
          }
        >
          <div className="modal-header bg-primary rounded-0">
            <h6 className="filter-modal-title"> Filter</h6>
            <button
              type="button"
              onClick={this.toggleFilter}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                className="navbar-toggler right-sidebar-toggler pointer"
              >
                ×
              </span>
            </button>
          </div>

          <div className="card-body">
            <form>

              {/* <div className="col-sm-12 ml-auto p-0 mb-2">
                <label>User Type</label>
                <select
                  className="form-control"
                  name="excelType"
                  value={this.state.excelType}
                  onChange={this.onChange}
                  id="excelType"
                >
                  <option value="ALL">All</option>
                  <option value="AG">Agent</option>
                  <option value="ASM">Area Sales Manager</option>
                  <option value="ASM2">Area Sales Manager 2</option>
                  <option value="SASM">Sr. AREA SALES MANAGER</option>
                </select>
              </div> */}

              {/* <Userdropdown
                ondropdownChange={this.ondropdownChangeagent}
                name="agent"
                value={this.state.agent}
                className="form-control"
                placeholder="User"
              /> */}

              <div className="form-group">
                <CustomerDropDown
                  ondropdownChange={(e) => this.ondropdownChangeagent("customer", e)}
                  placeholder="Search Customer"
                  filterBy={this.state.filterCustomer}
                  value={this.state.customer}
                />
              </div>

              <div>
                {
                  brandmanagement_res && brandmanagement_res.list && brandmanagement_res.list.length > 0 ?
                    <div className="col-sm-12 ml-auto p-0 mb-2">
                      <label>Brand</label>
                      <select className="form-control" name="brand_filter" value={this.state.brand_filter}
                        onChange={(e) => this.onChange(e)}>
                        <option value="">Select Brand</option>
                        {
                          brandmanagement_res.list.map(element =>
                            // console.log(element.name)
                            element.name ? <option value={element._id} key={element._id}>{element.name}</option> : "NA"
                          )
                        }
                      </select>
                    </div>
                    : null
                }
              </div>

              <div className="row">
                <div className="form-group col-6">
                  <label className="control-label">Created Start Date</label>
                  <input type="date" className="form-control" name="startdate" value={this.state.startdate} onChange={this.onChange} id="start-date" placeholder="Start Date" />
                </div>
                <div className="form-group col-6">
                  <label className="control-label">Created End Date</label>
                  <input type="date" className="form-control" name="enddate" value={this.state.enddate} onChange={this.onChange} id="end-date" placeholder="End Date" />
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="Mobile" className="control-label">Order From</label>
                <select
                  className="form-control"
                  name="requestFrom"
                  value={this.state.requestFrom}
                  onChange={this.onChange}>
                  <option value="">Select a Type</option>
                  <option value="ALL">All</option>
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </div> */}

            </form>
          </div>

          <div className="col-md-6 mb-4 offset-md-3 text-center">
            <button
              onClick={this.loadResult}
              data-dismiss="modal"
              type="button"
              aria-hidden="true"
              className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1"
            >
              Apply{" "}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              onClick={this.resetfilter}
              className="btn btn-outline-dark"
            >
              Reset
            </button>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile_single: state.profile_single,
    ordermanagement_res: state.ordermanagement_res,
    // order_delete: state.order_delete,
    orderUploadresponse: state.orderUploadresponse,
    orderexportresponse: state.orderexportresponse,
    customermanagement_res: state.customermanagement_res,
    orderConfirm_pack: state.orderConfirm_pack,
    outstandingDaysResponse: state.outstandingDaysResponse,
    brandmanagement_res: state.brandmanagement_res,
    uommanagement_res: state.uommanagement_res,
    order_reject: state.order_reject
  };
}

const mapDispatchToProps = (dispatch) => ({
  uommanagementlist: data => dispatch(uommanagementlist(data)),
  profilesingle: data => dispatch(profilesingle(data)),
  ordermanagementlist: (data) => dispatch(ordermanagementlist(data)),
  customermanagementlist: (data) => dispatch(customermanagementlist(data)),
  orderConfirm: (data, id) => dispatch(orderConfirm(data, id)),
  // delete_order: item_id => dispatch(delete_order(item_id)),
  orderexport: (data) => dispatch(orderexport(data)),
  outstandingDays: (id) => dispatch(outstandingDays(id)),
  brandmanagementlist: data => dispatch(brandmanagementlist(data)),
  orderReject: (id) => dispatch(orderReject(id))
});

RetailerOrdermanagement = connect(mapStateToProps, mapDispatchToProps)(RetailerOrdermanagement);

export default RetailerOrdermanagement;
