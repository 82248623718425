import React, { Component } from 'react';
import { connect } from 'react-redux';
import { profilesingle, updateprofile, state_search, city_search } from '../../actions';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import CountryDropdown from '../../components/address/countrydropdown';
import StateDropdown from '../../components/address/stateDropdown'
import CityDropdown from '../../components/address/citydropdown';
import { apiUrl, uploadUrl } from '../../config';
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { errorRender, getValue, isPermission, successRedriect, validate } from '../../helpers/functions';
import ErrorView from '../../components/errorView';
import TextAreaInputField from '../../components/textAreaInputField';
class Profile extends Component {
  state = {
    id: '',
    name: '',
    email: '',
    phone: '',
    country: null,
    state: null,
    city: null,
    line1: "",
    postalCode: '',
    type: "",
    company: "",
    logo: [],
    uploadPercentage: 0,
    setFields: [
      { name: "name", stateName: "name", type: "text" },
      { name: "email", stateName: "email", type: "text" },
      { name: "phone", stateName: "phone", type: "text" },
      { name: "country", stateName: "country", type: "select" },
      { name: "state", stateName: "state", type: "select" },
      { name: "city", stateName: "city", type: "select" },
      { name: "address.line1", stateName: "line1", type: "text" },
      { name: "address.postalCode", stateName: "postalCode", type: "text" },
      { name: "logo", stateName: "logo", type: "text" },
      { name: "_id", stateName: "id", type: "text" },
      { name: "auth.apiSecret", stateName: "apiSecret", type: "text" },
      { name: "isActive", stateName: "isActive", type: "text" }
    ],
    error: {
      nameError: "",
      emailError: "",
      phoneError: "",
      line1Error: "",
      postalCodeError: ""
    },
    isLoading: false,
    apiSecret: "",
    isActive: true
  }

  componentDidMount() {
    let data = {}
    this.props.profilesingle(data);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      successRedriect(nextProps, "profile_update", "Profile", this.props, "maindashboard", "Updated");
      if (nextProps.profile_single && nextProps.profile_single.success === true) {
        nextProps.profile_single.success = null;
        if (nextProps.profile_single.item) {
          this.state.setFields.forEach((value) => {
            if (value.type === "text") {
              this.setState({ [value.stateName]: getValue(nextProps.profile_single.item, value.name) })
            } else if (value.type === "select" && nextProps.profile_single[value.name]) {
              let obj = {
                label: nextProps.profile_single[value.name].name,
                value: nextProps.profile_single[value.name]._id
              }
              this.setState({ [value.stateName]: obj });
              this.ondropdownChange(obj, value.name);
              obj = null
            }
          })
        }
      }
      errorRender(nextProps, "profile_update");
    }
    return true;
  }


  ondropdownChange = (val, name) => {
    if (val && val.value) {
      if (name === "country") {
        if (this.state.country !== null) {
          if (val.value !== this.state.country.value) {
            this.setState({ state: null, city: null });
            let data = {
              country: val.value
            };
            this.props.state_search(data)
          }
        } else {
          let data = {
            country: val.value
          };
          this.props.state_search(data)
        }
      }
      if (name === "state") {
        if (this.state.state !== null) {
          if (val.value !== this.state.state.value) {
            this.setState({ city: null });
            let data = {
              state: val.value
            };
            this.props.city_search(data);
          }
        } else {
          let data = {
            state: val.value
          };
          this.props.city_search(data);
        }
      }
      this.setState({ [name]: val });
    }
  }

  uploadFile = ({ target: { files } }) => {
    // console.log(files[0])
    let data = new FormData();
    data.append('file', files[0])
    const token = localStorage.getItem('token');
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    axios.defaults.headers = { 'x-access-token': token }
    axios.post(apiUrl + "/api/account/profile/upload", data, options).then(res => {
      // console.log(res)
      this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 })
        }, 1000);
      })
    })
  }

  handleChange = (name, e) => {
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [name]: e.target.value });
    }
  }

  onChange = (e) => {
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onsubmit = (e) => {
    let validationFields = [
      { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
      { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" },
      { name: 'phone', label: "Mobile", value: this.state.phone, hasError: false, isRequired: true, isNumber: true, onlyChar: false, minLength: 10, maxLength: 10, type: "text", message: "", errName: "phoneError" },
      { name: 'country', label: "Country", value: this.state.country, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "countryError" },
      { name: 'state', label: "State", value: this.state.state, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "stateError" },
      { name: 'city', label: "City", value: this.state.city, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "cityError" },
      { name: 'line1', label: "Address", value: this.state.line1, hasError: false, isRequired: false, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "line1Error" },
      { name: 'postalCode', label: "Zip Code", value: this.state.postalCode, hasError: false, isRequired: false, isNumber: false, onlyChar: false, minLength: 8, maxLength: 8, type: "text", message: "", errName: "postalCodeError" },
      { name: 'logo', label: "Logo", value: this.state.logo, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "logoError" }
    ]
    let data = {
      id: this.state.id,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      company: this.state.company,
      logo: this.state.logo,
      address: {
        country: this.state.country && this.state.country.value,
        state: this.state.state && this.state.state.value,
        city: this.state.city && this.state.city.value,
        line1: this.state.line1,
        postalCode: this.state.postalCode
      }
    }

    e.preventDefault();

    let { newArr, valid } = validate(validationFields);

    newArr.forEach((el) => this.setState({ [el.errName]: el.message }));

    if (valid) {
      this.setState({ isLoading: true });
      this.props.updateprofile(data);
      validationFields = null;
      newArr = null;
    }

  }

  render() {
    const { uploadPercentage, isLoading } = this.state;
    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <div className="card-title" id="update_profile">
                    Update Profile
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.phoneError} handleChange={this.handleChange} type="text" name="phone" value={this.state.phone} className="form-control" id="phone" placeholder="Mobile" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} type="email" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" readOnly />
                    </div>
                    {
                      this.state.logo && this.state.logo.length !== 0 ?
                        <div className="form-group required col-12 col-lg-3">
                          <label htmlFor="logo" className="control-label">Logo</label>
                          <div className="document-media">
                            <div onClick={() => { this.setState({ logo: [] }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                            <img alt="img" className="img" src={uploadUrl + this.state.logo.medium} />
                            <div className="media-content">
                            </div>
                          </div>
                        </div> :
                        <div className="form-group required col-12 col-lg-3">
                          <label htmlFor="logo" className="control-label">Logo</label>
                          <input
                            maxLength="5"
                            onChange={this.uploadFile}
                            // value={this.state.code}
                            name="logo"
                            type="file"
                            className="form-control"
                            accept='.jpeg, .jpg, .png'
                          />
                          <ErrorView message={this.state.logoError} />
                          <div className="progressbar-image">
                            {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                          </div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <div className="card-title" id="update_address">
                    Update Address
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-12 col-lg-4">
                      <CountryDropdown
                        value={this.state.country}
                        ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                        placeholder="Search Country"
                        errMessage={this.state.countryError}
                      />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <StateDropdown
                        ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                        value={this.state.state}
                        placeholder="Search State"
                        errMessage={this.state.stateError}
                        country={this.state.country}
                      />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <CityDropdown
                        ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                        value={this.state.city}
                        placeholder="Search City"
                        state={this.state.state}
                        country={this.state.country}
                        errMessage={this.state.cityError}
                      />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <TextAreaInputField errMessage={this.state.line1Error} onChange={this.onChange} name="line1" value={this.state.line1} className="form-control" id="line1" rows="2" placeholder="Address line-1" />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <Inputfield errMessage={this.state.postalCodeError} maxlength="8" handleChange={this.handleChange} type="text" name="postalCode" value={this.state.postalCode} className="form-control" id="postalCode" placeholder="Zip Code" />
                    </div>
                    {/* {
                      this.state.logo && this.state.logo.length !== 0 ?
                        <div className="col-12 col-lg-3">
                          <div className="document-media">
                            <div onClick={() => { this.setState({ logo: [] }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                            <img alt="img" className="img" src={uploadUrl + this.state.logo.medium} />
                            <div className="media-content">
                            </div>
                          </div>
                        </div> :
                        <div className="form-group required ">
                          <label htmlFor="logo" className="control-label">Logo</label>
                          <input
                            maxLength="5"
                            onChange={this.uploadFile}
                            // value={this.state.code}
                            name="logo"
                            type="file"
                            className="form-control"
                          />
                          <ErrorView message={this.state.logoError} />
                          <div className="progressbar-image">
                            {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                          </div>
                        </div>
                    } */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            this.state.isActive ?
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-shadow mb-4">

                    <div className="card-header">
                      <div className="card-title" id="update_address">
                        Third Party Details
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-12 col-lg-4">
                          <Inputfield type="text" disabled="true" name="apiKey" value={this.state.id} className="form-control" id="API Key" placeholder="API Key" />
                        </div>
                        <div className="form-group col-12 col-lg-4">
                          <Inputfield type="text" disabled="true" name="apiSecret" value={this.state.apiSecret} className="form-control" id="API Secret" placeholder="API Secret" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div> : null
          }

          <div className="row">
            <div className="col-md-6 mb-4 offset-md-3 text-center">
              {isPermission("profile", "update") ? 
                <Buttons onclick={this.onsubmit} type="submit" disable={isLoading} loading={isLoading} className="btn btn-primary mr-1" name="Update" />
              : null}
              <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
            </div>
          </div>

        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile_single: state.profile_single,
    profile_update: state.profile_update
  };
}


const mapDispatchToProps = dispatch => ({
  profilesingle: data => dispatch(profilesingle(data)),
  updateprofile: (data) => dispatch(updateprofile(data)),
  state_search: data => dispatch(state_search(data)),
  city_search: data => dispatch(city_search(data)),
});

Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

export default Profile;