import React, { Component } from 'react';
import { connect } from 'react-redux';
import { retailerlist, useractive, delete_retailer } from '../../actions';
import { routehelp } from '../../Routehelper';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import LinkWithItag from '../../components/linkwithItag';
import ButtonWithItag from '../../components/buttonwithTagi';
import Loader from '../../components/loader';
import DataTable from '../../components/dataTalble';
import { errorRender, successRender, commonDebounce, isPermission } from '../../helpers/functions';

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = '';
let sort = '';
class Retailmanagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobile: '',
            isLoading: false,
            isFilter: false,
            deleteMessage: 'Retailer has been Deleted Successfully',
            successMessage: 'Successfully Done',
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'email',
                    label: "Email",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'phone',
                    label: "Mobile",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: this.redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }


    componentDidMount() {
        currentPage = 1
        itemsPerPage = 50
        this.loadResult()
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined, email: this.state.email, phone: this.state.mobile }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.retailerlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }


    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', mobile: '' }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.retailerlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', mobile: '' })
    }


    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }


    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_retailer(item._id);
            e.preventDefault();
        }
    }

    redirect = (e, item) => {
        this.props.history.push(routehelp.retailUpdate + item._id);
        e.preventDefault();
    }

    shouldComponentUpdate(nextProps) {
        if (this.state.isLoading) {
            this.setState({ isLoading: false })
        }
        errorRender(nextProps, "useractive_update", this.loadResult);
        successRender(nextProps, "useractive_update", this.loadResult, this.state.successMessage);
        successRender(nextProps, "retailer_delete", this.loadResult, this.state.deleteMessage);
        errorRender(nextProps, "retailer_delete");
        return true;
    }


    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();

    render() {
        const { retailerlist_res } = this.props;
        const { name } = this.state;
        const totResult = retailerlist_res !== undefined ? retailerlist_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Retailer Users
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <div className="input-group mr-sm-2 search-group">
                                                        <input onChange={this.onChangeforDebounce} value={name} type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="search..." />
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text"><i className="fa fa-search "></i></div>
                                                        </div>
                                                    </div>
                                                    {isPermission("userRetailers", "create") ? 
                                                        <LinkWithItag to={routehelp.retailAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Retailer" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"userRetailers"} /> :
                                            this.state.isLoading ? <Loader /> : retailerlist_res !== undefined && retailerlist_res.count === 0 ? "No Data Found" : retailerlist_res && retailerlist_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={retailerlist_res} moduleName={"userRetailers"} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" className="close" onClick={this.toggleFilter} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            {/* <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" name="name" value={this.state.name} onChange={this.onChange} className="form-control" id="name" placeholder="Name" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Mobile</label>
                                <div className="col-sm-9">
                                    <input maxLength="10" type="text" name="mobile" value={this.state.mobile} onChange={this.onChange} className="form-control" id="Mobile" placeholder="Mobile" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        retailerlist_res: state.retailerlist_res,
        retailer_delete: state.retailer_delete,
        useractive_update: state.useractive_update

    };
}


const mapDispatchToProps = dispatch => ({
    retailerlist: data => dispatch(retailerlist(data)),
    delete_retailer: item_id => dispatch(delete_retailer(item_id)),
    useractive: (data, id) => dispatch(useractive(data, id))
});

Retailmanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Retailmanagement);

export default Retailmanagement;