import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { stockUpload, stockexport, productmanagementlist, stockmanagementlist, stockHistory_list, stockHistory_get, companyConfigSingle } from '../../actions';
import Translate from '../../libs/translation';
import 'reactjs-toastr/lib/toast.css';
import { CSVLink } from "react-csv";
import { errorRender, commonDebounce, loaderUpdate, fileDownload, redirect, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import toastr from 'reactjs-toastr';
require('react-datetime');
let dateFormat = require('dateformat');


const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Loader = lazy(() => import('../../components/loader'));
const SingleBrandDropDown = lazy(() => import('../brandsManagement/singleBrandDropDown'));
const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();


const csvData = [
    ["product", "shade", "quantity", "clsqty", "fwqty"],
    ["TEST-001", "6", "50", "40", "50"],
    ["TEST-002", "7", "10", "70", "60"],
    ["TEST-002", "6", "10", "10", "70"]
];
class Productmanagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            uploadSelection: "overwrite",
            brand: null,
            design: '',
            isFilter: false,
            filterBrand: null,
            isExportLoading: false,
            isStockDetails: false,
            isStockHistory: false,
            stockHistoryList: [],
            stockDetailsList: [],
            productDetails: {},
            stockHistoryObj: {},
            serverError: ["stockHistory_get_res"],
            webFeature: {}
        }
    }

    async componentDidMount() {
        this.props.companyConfigSingle({});
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1
        itemsPerPage = 50
        this.setState({});
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        file = new FormData();
        let data = {}
        data.filter = { brand: this.state.filterBrand && this.state.filterBrand.value ? this.state.filterBrand.value : undefined, name: this.state.design ? this.state.design : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.stockmanagementlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }


    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });


    export = () => {

        this.setState({
            isExportLoading: true
        });

        let data = {
            filter: {
                brand: this.state.filterBrand && this.state.filterBrand.value ? this.state.filterBrand.value : undefined,
                // name: this.state.design ? this.state.design : undefined
            }
        };

        // console.log(data);
        this.props.stockexport(data);

    }

    ondropdownChange = (e, t) => {
        this.setState({ [t]: e });
    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            uploadSelection: changeEvent.target.value
        });
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    resetfilter = () => {
        this.setState({ isLoading: true, isFilter: false, filterBrand: null, code: '', design: '', isStockDetails: false, isStockHistory: false })
        let data = {}
        data.filter = { name: undefined, code: undefined, brand: undefined }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.stockmanagementlist(data);
    }

    handleFile = event => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
                file.append('brand', this.state.brand && this.state.brand.value);
                file.append('option', this.state.uploadSelection);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        if (this.state.brand === null) {
            return toastr.error('Please Select Brand ', 'error', {
                displayDuration: 2000
            });
        } else if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select File ', 'error', {
                displayDuration: 2000
            });
        } else {
            this.props.stockUpload(file);
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };


    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }


    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    clearFile = () => {
        this.setState({ brand: "" });
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (nextProps !== this.props) {
            if (this.state.isLoaderOn) {
                this.setState({
                    isLoaderOn: false
                });
            }

            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "productmanagement_res") });
            };

            errorRender(nextProps, "productUploadresponse");

            if (nextProps.stockexportresponse !== undefined && nextProps.stockexportresponse.success === true) {
                this.setState({
                    isExportLoading: false
                });
                fileDownload(nextProps, "stockexportresponse", "fileName");
            }

            if (nextProps.stockUploadresponse !== undefined) {
                if (nextProps.stockUploadresponse.success === true) {
                    let str = `
                    B2B Total Records - ${nextProps.stockUploadresponse.totalCount} </br>
                    B2B Success Records - ${nextProps.stockUploadresponse.successCount} </br> 
                    B2B Failed Records - ${nextProps.stockUploadresponse.totalCount - nextProps.stockUploadresponse.successCount}  </br>
                    B2C Total Records - ${nextProps.stockUploadresponse.clsCount} </br>
                    B2C Success Records - ${nextProps.stockUploadresponse.clsTotalSuccessCount} </br> 
                    B2C Failed Records - ${nextProps.stockUploadresponse.clsCount - nextProps.stockUploadresponse.clsTotalSuccessCount} </br>
                    Forward Total Records - ${nextProps.stockUploadresponse.fwCount} </br>
                    Forward Success Records - ${nextProps.stockUploadresponse.fwTotalSuccessCount} </br> 
                    Forward Failed Records - ${nextProps.stockUploadresponse.fwCount - nextProps.stockUploadresponse.fwTotalSuccessCount}
                    `;
                    toastr.success(str, "Upload Details", {
                        displayDuration: 10000
                    });
                    this.setState({ brand: null });
                    document.getElementById("myFile").value = "";
                    this.loadResult();
                    nextProps.stockUploadresponse.success = null;
                }
            };

            if (nextProps.stockUploadresponse !== undefined && nextProps.stockUploadresponse.success === false && this.props !== nextProps) {
                nextProps.stockUploadresponse.errors.map((item, index) =>
                    toastr.error(Translate.translate(req, item.code), 'Error', {
                        displayDuration: 5000
                    })
                );
                this.setState({ brand: "" });
                document.getElementById("myFile").value = "";
                nextProps.stockUploadresponse.success = null;
            };

            if (nextProps.stockHistory_get_res !== undefined && nextProps.stockHistory_get_res.success === true) {
                this.setState({
                    stockHistoryList: nextProps.stockHistory_get_res.list || []
                });
            };

            if (nextProps.companyConfigSingle_res && nextProps.companyConfigSingle_res.success === true) {
                // nextProps.companyConfigSingle_res.success = null;
                if (nextProps.companyConfigSingle_res.item) {
                    this.setState({
                        webFeature: nextProps.companyConfigSingle_res.item.webFeature
                    });
                }
            };

            this.state.serverError.forEach((el) => errorRender(nextProps, el));
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ design: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    openStockDetails = (item, flag = "productName", shade) => {
        // console.log(item);
        if (flag === "shade") {
            let findItem = item.attributes.filter((ele) => ele.name === shade.name);
            // console.log(findItem);
            this.setState({
                isStockDetails: true,
                stockDetailsList: findItem,
                isStockHistory: false,
                productDetails: item
            });
        } else {
            this.setState({
                isStockDetails: true,
                isStockHistory: false,
                stockDetailsList: item.attributes || [],
                productDetails: item
            });
        };
    }

    openStockUpload = () => {
        this.setState({
            isStockDetails: false,
            isStockHistory: false
        });
    }

    openStockHistory = (shade, item) => {
        // console.log(shade, item);
        this.setState({
            isStockHistory: true,
            isStockDetails: false
        });

        let attr = item.attributes.filter((ele) => ele.name === shade.name);
        // console.log(attr[0]._id);
        this.props.stockHistory_get(attr[0]._id);
        this.setState({
            stockHistoryObj: {
                brand: item.brand.name || null,
                product: item.name || null,
                shade: attr[0].name || null
            },
            isLoaderOn: true
        });
    };

    render() {
        const { productmanagement_res } = this.props;
        const { design } = this.state;
        const totResult = productmanagement_res !== undefined ? productmanagement_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <div className="card-title">
                                        Stock Management
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={design} />
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} type="button" onclick={this.export} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-1" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" onclick={(e) => this.openStockUpload()} />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"sampleStock.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>



                                        {
                                            totResult === undefined ? "No Record Found" :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="table-responsive">
                                                                <table id="bs4-table" className="table table-bordered table-striped dataTable" cellSpacing="0">
                                                                    <thead>
                                                                        <tr role="row">
                                                                            <th onClick={() => this.sortBy('name')} className={sort === ''
                                                                                ? 'sorting'
                                                                                : sort === 'asc'
                                                                                    ? 'sorting_asc'
                                                                                    : 'sorting_desc'}>Brand </th>
                                                                            <th onClick={() => this.sortBy('code')} className={sort === ''
                                                                                ? 'sorting'
                                                                                : sort === 'asc'
                                                                                    ? 'sorting_asc'
                                                                                    : 'sorting_desc'}>Design No. </th>
                                                                            <th>
                                                                                <div className='d-flex row justify-content-between align-items-center'>
                                                                                    <div className='col-md-3'>Shade</div>
                                                                                    <div className='col-md-3'>Main<sub>(qty)</sub></div>
                                                                                    <div className='col-md-3'>CLS<sub>(qty)</sub></div>
                                                                                    <div className='col-md-3'>Forward<sub>(qty)</sub></div>
                                                                                </div>
                                                                            </th>
                                                                            <th onClick={() => this.sortBy('createdAt')} className={sort === ''
                                                                                ? 'sorting'
                                                                                : sort === 'asc'
                                                                                    ? 'sorting_asc'
                                                                                    : 'sorting_desc'}>CreatedAt</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            productmanagement_res !== undefined && totResult !== 0 ? productmanagement_res.list.map((item, index) =>
                                                                                <tr key={index} role="row" className="odd notification-list">
                                                                                    <td>{item.brand ? item.brand.name : ""}</td>
                                                                                    <td>
                                                                                        <button title={"stock details"} className={"btn btn-link text-primary action-btn p-0"}
                                                                                            disabled={false}
                                                                                            type={"button"}
                                                                                            data-original-title={"Click to Stock Details"} data-target={".bd-example-modal-lg"}
                                                                                            onClick={() => this.openStockDetails(item)} data-placement={"top"} data-toggle={"modal"}>
                                                                                            {/* <i className={classNameI}></i> */}
                                                                                            {item.name || "NA"}
                                                                                        </button>
                                                                                    </td>
                                                                                    <td className='pb-0'>
                                                                                        {
                                                                                            item.stockAttributes === undefined || item.stockAttributes.length === 0 ?
                                                                                                // eslint-disable-next-line
                                                                                                item.attributes.sort((a, b) => (parseInt(a.name) > parseInt(b.name)) ? 1 : ((parseInt(b.name) > parseInt(a.name)) ? -1 : 0)).map((items, index) => {
                                                                                                    return <div key={index} className="shade-q"> <span>{items.name || "NA"}</span>   <span> {0} </span></div>
                                                                                                })
                                                                                                // eslint-disable-next-line
                                                                                                :
                                                                                                item.stockAttributes.sort((a, b) => (parseInt(a.name) > parseInt(b.name)) ? 1 : ((parseInt(b.name) > parseInt(a.name)) ? -1 : 0)).map((itemstock, indexs) => {
                                                                                                    return (
                                                                                                        <div key={indexs} className="shade-q row mx-0">
                                                                                                            <div className='col-12 col-md-3'>
                                                                                                                <button title={"stock details"} className={"btn btn-link text-primary action-btn p-0 shade-link"}
                                                                                                                    disabled={false}
                                                                                                                    type={"button"}
                                                                                                                    data-original-title={"Click to Stock Details"} data-target={".bd-example-modal-lg"}
                                                                                                                    onClick={() => this.openStockDetails(item, "shade", itemstock)} data-placement={"top"} data-toggle={"modal"}>
                                                                                                                    {/* <i className={classNameI}></i> */}
                                                                                                                    {itemstock.name || "NA"}
                                                                                                                </button>
                                                                                                                {/* {itemstock.name || "NA"} */}
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className='col-md-3'>
                                                                                                                {
                                                                                                                    this.state.webFeature && this.state.webFeature.stockHistory ?
                                                                                                                        <button title={"Stock History Details"} className={"btn btn-link text-primary action-btn p-0 shade-link"}
                                                                                                                            disabled={false}
                                                                                                                            type={"button"}
                                                                                                                            data-original-title={"Click to Stock History Details"} data-target={".bd-example-modal-lg"}
                                                                                                                            onClick={() => this.openStockHistory(itemstock, item)} data-placement={"top"} data-toggle={"modal"}>
                                                                                                                            {itemstock.quantity || 0}
                                                                                                                        </button> :
                                                                                                                        (itemstock.quantity || 0)
                                                                                                                }
                                                                                                            </div>

                                                                                                            {
                                                                                                                item.clsStockAttributes && item.clsStockAttributes.length > 0 ? item.clsStockAttributes.sort((a, b) => (parseInt(a.name) > parseInt(b.name)) ? 1 : ((parseInt(b.name) > parseInt(a.name)) ? -1 : 0)).map((stock, ind) => {
                                                                                                                    if (JSON.stringify(itemstock.name) === JSON.stringify(stock.name)) {
                                                                                                                        return <div className='col-md-3' key={itemstock.name + ind}>
                                                                                                                            {
                                                                                                                                this.state.webFeature && this.state.webFeature.stockHistory ?
                                                                                                                                    <button title={"Stock History Details"} className={"btn btn-link text-primary action-btn p-0 shade-link"}
                                                                                                                                        disabled={false}
                                                                                                                                        type={"button"}
                                                                                                                                        data-original-title={"Click to Stock History Details"} data-target={".bd-example-modal-lg"}
                                                                                                                                        onClick={() => this.openStockHistory(itemstock, item)} data-placement={"top"} data-toggle={"modal"}>
                                                                                                                                        {stock.quantity || 0}
                                                                                                                                    </button> :
                                                                                                                                    (stock.quantity || 0)
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                }) :
                                                                                                                    <div className='col-md-3'>{0}</div>
                                                                                                            }

                                                                                                            {
                                                                                                                item.forwardStockAttributes && item.forwardStockAttributes.length > 0 ? item.forwardStockAttributes.sort((a, b) => (parseInt(a.name) > parseInt(b.name)) ? 1 : ((parseInt(b.name) > parseInt(a.name)) ? -1 : 0)).map((stock, ind) => {
                                                                                                                    if (JSON.stringify(itemstock.name) === JSON.stringify(stock.name)) {
                                                                                                                        return <div className='col-md-3' key={itemstock.name + ind}>
                                                                                                                            {
                                                                                                                                this.state.webFeature && this.state.webFeature.stockHistory ?
                                                                                                                                    <button title={"Stock History Details"} className={"btn btn-link text-primary action-btn p-0 shade-link"}
                                                                                                                                        disabled={false}
                                                                                                                                        type={"button"}
                                                                                                                                        data-original-title={"Click to Stock History Details"} data-target={".bd-example-modal-lg"}
                                                                                                                                        onClick={() => this.openStockHistory(itemstock, item)} data-placement={"top"} data-toggle={"modal"}>
                                                                                                                                        {stock.quantity || 0}
                                                                                                                                    </button> :
                                                                                                                                    (stock.quantity || 0)
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                }) : <div className='col-md-3'>{0}</div>
                                                                                                            }

                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                        }
                                                                                    </td>

                                                                                    <td>{dateFormat(new Date(item.createdAt), 'mmm d, yyyy')}</td>

                                                                                    <td>
                                                                                        {isPermission("stock", "update") ? 
                                                                                            <ButtonWithItag title="edit" onclick={(ev) => redirect(ev, item, this.props)} type="button" classNameI="ti-pencil" className="btn btn-link text-pramiry action-btn mr-2 p-0" />
                                                                                        : null}
                                                                                    </td>
                                                                                </tr>
                                                                            ) :
                                                                                <tr><td colSpan="50" className="width: 100%;" style={{ textAlign: "center" }} >No Data Found</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                        }
                                        {
                                            totResult !== undefined ?
                                                <Pagination
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                    handlePage={this.handlePage}
                                                    handleNext={this.handleNext}
                                                    handlePrevious={this.handlePrevious}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    totResult={totResult}
                                                    key={currentPage}
                                                />
                                                : ""
                                        }
                                    </div>

                                    {
                                        this.state.isStockDetails ?
                                            <div
                                                className="modal fade bd-example-modal-lg"
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="myLargeModalLabel"
                                                style={{ display: "none" }}
                                                aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="myLargeModalLabel">
                                                                {this.state.productDetails.name || "Product Details"}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true" className='cursor' title='close'>×</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <table className="table table-bordered dataTable" cellSpacing="0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Shade Name</th>
                                                                            <th>Shade Code</th>
                                                                            <th>Shade Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.stockDetailsList && this.state.stockDetailsList.length > 0 ?
                                                                                this.state.stockDetailsList.map((ele, ind) => {
                                                                                    return <tr key={ind}>
                                                                                        <td>
                                                                                            {ele.name}
                                                                                        </td>
                                                                                        <td>
                                                                                            {ele.code}
                                                                                        </td>
                                                                                        <td>
                                                                                            {ele.description}
                                                                                        </td>
                                                                                    </tr>
                                                                                })
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan='4' className='text-center'>
                                                                                        No data Found
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            this.state.isStockHistory && this.state.webFeature && this.state.webFeature.stockHistory ?
                                                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="myLargeModalLabel">Stock History</h5>
                                                                <button type="button" className="close cursor-pointer" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <div className='col-md-6'>Brand</div>
                                                                    <div className='col-md-6'>{this.state.stockHistoryObj.brand}</div>
                                                                    <div className='col-md-6'>Product</div>
                                                                    <div className='col-md-6'>{this.state.stockHistoryObj.product}</div>
                                                                    <div className='col-md-6'>Shade</div>
                                                                    <div className='col-md-6'>{this.state.stockHistoryObj.shade}</div>
                                                                </div>
                                                                <div>
                                                                    <table className="table table-bordered dataTable" cellSpacing="0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th>Stock B2B</th>
                                                                                <th>Stock B2C</th>
                                                                                <th>Type</th>
                                                                                <th>Order No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.isLoaderOn ?
                                                                                    <tr>
                                                                                        <td colSpan={5} className='text-center'>
                                                                                            <Loader />
                                                                                        </td>
                                                                                    </tr>
                                                                                    :
                                                                                    this.state.stockHistoryList && this.state.stockHistoryList.length > 0 ?
                                                                                        this.state.stockHistoryList.map((ele, index) => {
                                                                                            return (
                                                                                                <tr key={"stockHis" + index}>
                                                                                                    <td>{dateFormat(new Date(ele.createdAt), 'mmm d, yyyy HH:MM')}</td>
                                                                                                    <td className={ele.processB2B === "inc" ? "text-success" : ele.processB2B === "desc" ? "text-danger" : "text-primary"}>{ele.b2bqty || 0}</td>
                                                                                                    <td className={ele.processB2C === "inc" ? "text-success" : ele.processB2C === "desc" ? "text-danger" : "text-primary"}>{ele.b2cqty || 0}</td>
                                                                                                    <td>{ele.type === "add" ? "Add" : ele.type === "order" ? "Order" : ele.type === "update" ? "Update" : "NA"}</td>
                                                                                                    <td>{ele.order ? ele.order.companyOrderNum : ""}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }) :
                                                                                        <tr>
                                                                                            <td colSpan={5} className='text-center'>No Record Found</td>
                                                                                        </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="myLargeModalLabel">Stock Upload</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="form-group required">
                                                                    <SingleBrandDropDown
                                                                        ondropdownChange={(e) => this.ondropdownChange(e, "brand")}
                                                                        placeholder="Search Brand"
                                                                        value={this.state.brand}
                                                                    />
                                                                </div>
                                                                <div className='row mt-1'>
                                                                    <div className='col-md-4'>
                                                                        <input type="radio" value="overwrite" name="uploadSelection" onChange={(e) => this.handleOptionChange(e)} checked={this.state.uploadSelection === 'overwrite'} /> Update Stock Quantity
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <input type="radio" value="add" name="uploadSelection" onChange={(e) => this.handleOptionChange(e)} checked={this.state.uploadSelection === 'add'} /> Add Stock Quantity
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-1'>
                                                                    <div className="custom-file mt-2 col">
                                                                        <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                                <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <SingleBrandDropDown
                                    ondropdownChange={(e) => this.ondropdownChange(e, "filterBrand")}
                                    placeholder="Search Brand"
                                    value={this.state.filterBrand}
                                />
                            </div>
                            {/* <div className=" form-group">
                                <label className="control-label">Design</label>
                                <Inputfield maxlength="20" handleChange={this.handleChange} type="text" name="design" value={this.state.design} className="form-control" id="exampleInputName1" ariadescribedby="codeHelp" placeholder="Design" />
                            </div> */}
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>

            </main>


        )
    }
}

function mapStateToProps(state) {
    return {
        productmanagement_res: state.productmanagement_res,
        stockUploadresponse: state.stockUploadresponse,
        stockexportresponse: state.stockexportresponse,
        stockHistory_list_res: state.stockHistory_list_res,
        stockHistory_get_res: state.stockHistory_get_res,
        companyConfigSingle_res: state.companyConfigSingle_res
    };
}


const mapDispatchToProps = dispatch => ({
    stockmanagementlist: data => dispatch(stockmanagementlist(data)),
    stockUpload: file => dispatch(stockUpload(file)),
    stockexport: data => dispatch(stockexport(data)),
    productmanagementlist: data => dispatch(productmanagementlist(data)),
    stockHistory_list: data => dispatch(stockHistory_list(data)),
    stockHistory_get: data => dispatch(stockHistory_get(data)),
    companyConfigSingle: data => dispatch(companyConfigSingle(data))
});

Productmanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Productmanagement);

export default Productmanagement;