import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { brandmanagementlist, brandmanageupdate } from '../../actions';
import { delete_brand } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const SearchInput = lazy(() => import('../../components/searchInput'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Loader = lazy(() => import('../../components/loader'));
const DataTable = lazy(() => import('../../components/dataTalble'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class Brandmanagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            isFilter: false,
            successRequest: [
                { name: "brand_delete", msg: 'Brand has been Deleted Successfully' },
                { name: "brand_update", msg: 'Brand Has been Updated Successfully' }
            ],
            serverError: ["brand_update", "brand_delete", "brandmanagement_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'isActive',
                    label: "Status",
                    type: "checkbox",
                    sortable: true,
                    isShow: true,
                    function: this.checkhandler
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        var params = this.queryStringParse(this.props.location.search);
        if (params && params.dashboard === 'true') {
            // console.log(params);
            this.setState({
                isBackButton: true
            });

        }
    }

    queryStringParse = function (string) {
        let parsed = {};
        if (string) {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.forEach((value) => {
                let params = value.split('=')
                parsed[params[0]] = params[1]
            });
        }
        return parsed;
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        this.setState = (state, callback) => {
            return;
        };
    }

    loadResult = () => {
        // console.log(this.state);
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined, code: this.state.code }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.brandmanagementlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.brandmanagementlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', code: '' })
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    checkhandler = (e, item) => {
        this.setState({ [e.target.name]: e.target.checked });
        let id = e.target.name;
        let data = {
            code: item.code,
            description: item.description,
            name: item.name,
            isActive: e.target.checked
        }
        this.props.brandmanageupdate(data, id);
    }

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_brand(item._id);
            e.preventDefault();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg))
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "brandmanagement_res") });
            }
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    render() {
        const { brandmanagement_res } = this.props;
        // console.log(brandmanagement_res);
        const { name } = this.state;
        const totResult = brandmanagement_res !== undefined ? brandmanagement_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                {
                                    this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                                        <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                    </div> : null
                                }
                                <div className="card-header">
                                    <div className="card-title">
                                        Brands Management
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("brand", "create") ? 
                                                        <LinkWithItag to={routehelp.Brandmanagementadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Brand" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>

                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"brand"}/> :
                                            this.state.isLoading ? <Loader /> : brandmanagement_res !== undefined && brandmanagement_res.count === 0 ? "No Data Found" : brandmanagement_res && brandmanagement_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={brandmanagement_res} props={this.props} moduleName={"brand"}/>
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}

                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>

                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button onClick={this.toggleFilter} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>

                    <div className="card-body">
                        <form>
                            {/* <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxLength="20" type="text" className="form-control" id="name" name="name" onChange={this.onChange} value={this.state.name} placeholder="Name" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Code</label>
                                <div className="col-sm-9">
                                    <input maxLength="10" type="text" className="form-control" name="code" value={this.state.code} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        brandmanagement_res: state.brandmanagement_res,
        brand_delete: state.brand_delete,
        brand_update: state.brand_update,
    };
}

const mapDispatchToProps = dispatch => ({
    brandmanagementlist: data => dispatch(brandmanagementlist(data)),
    delete_brand: item_id => dispatch(delete_brand(item_id)),
    brandmanageupdate: (data, id) => dispatch(brandmanageupdate(data, id)),
});

Brandmanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Brandmanagement);

export default Brandmanagement;