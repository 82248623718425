export const login = data => ({ type: 'login', data }); //signin

export const logout = data => ({ type: 'logout', data }); // logout

export const requestotp = data => ({ type: 'requestotp', data }); // request opt 

export const validateotp = data => ({ type: 'validateotp', data }); //validate Otp

//custom notifications
export const sendNotifications = data => ({ type: 'customNotificationSend', data }) //send custom notification

// User Management starts Here 
export const usermanagementlist = data => ({ type: 'usermanagementlist', data }); //user management list

export const inactiveUserslist = data => ({ type: 'inactiveUserslist', data }); //in active user management list

export const zeroStockList = data => ({ type: 'zeroStockList', data }); //Zero Stock list

export const inactiveCustomerslist = data => ({ type: 'inactiveCustomerslist', data }); //in active customers management list

export const usermanagecreate = data => ({ type: 'usermanagecreate', data }); //user management create

export const usersingle = data => ({ type: 'usersingle', data }); //get single user details 

export const delete_user = item_id => ({ type: 'delete_user', item_id }); //user delete  

export const usermanageupdate = (data, id) => ({ type: 'usermanageupdate', data, id }); //user update details 

export const agent_search = (data) => ({ type: 'agent_search', data }); //agent search details 

export const user = (data) => ({ type: 'user', data }); //user search details 

export const useractive = (data, id) => ({ type: 'useractive', data, id }); //user active  

export const userExport = data => ({ type: 'userExport', data }); //User export .

export const inactiveCustomersExport = data => ({ type: 'inactiveCustomersExport', data }); //inactive customers export

export const inactiveUsersExport = data => ({ type: 'inactiveUsersExport', data }); //inactiveUsers export

export const zeroStockExport = data => ({ type: 'zeroStockExport', data }); //zeroStock export


//User management ends Here 


// Zone Management Starts Here 
export const zonemanagementlist = data => ({ type: 'zonemanagementlist', data }); //Zone management list

export const zonemanagecreate = data => ({ type: 'zonemanagecreate', data }); //zone management create

export const delete_zone = item_id => ({ type: 'delete_zone', item_id }); //Zone delete 

export const zonesingle = data => ({ type: 'zonesingle', data }); //get single zone details 

export const zonemanageupdate = (data, id) => ({ type: 'zonemanageupdate', data, id }); //zone update details 

export const zone_search = (data) => ({ type: 'zone_search', data }); //zone search details 
// Zone Management Ends Here

//UOM Management starts here
export const uommanagementlist = data => ({ type: 'uommanagementlist', data }); //UOM management list

export const uommanagecreate = data => ({ type: 'uommanagecreate', data }); //UOM management create

export const delete_uom = item_id => ({ type: 'delete_uom', item_id }); //UOM delete 

export const uomsingle = data => ({ type: 'uomsingle', data }); //get single uom details 

export const uommanageupdate = (data, id) => ({ type: 'uommanageupdate', data, id }); //uom update details 

export const uom_search = (data) => ({ type: 'uom_search', data }); //uom search details 

export const uom_product_search = (data) => ({ type: 'uom_product_search', data });//uom product search

//order Failed List
export const orderFailedList = data => ({ type: 'orderFailedList', data }); //order failed management list

//Commission Management starts here
export const commissionList = data => ({ type: 'commissionList', data }); //commission management list

export const delete_commission = item_id => ({ type: 'delete_commission', item_id }); //commission delete 

export const commissionSingle = (item_id, data) => ({ type: 'commissionSingle', item_id, data }); //get single commission details 

//user hierarchy
export const userHierarchyList = data => ({ type: 'userHierarchyList', data }); //user Hierarchy list

export const userHierarchyCreate = data => ({ type: 'userHierarchyCreate', data }); //user Hierarchy create

export const userHierarchyDelete = item_id => ({ type: 'userHierarchyDelete', item_id }); //User Hierarchy delete 

export const userHierarchySingle = data => ({ type: 'userHierarchySingle', data }); //get single user Hierarchy details 

export const userHierarchyUpdate = (data, id) => ({ type: 'userHierarchyUpdate', data, id }); //user hierarchy update details


//Region Management Starts Here
export const regionmanagementlist = data => ({ type: 'regionmanagementlist', data }); //region management list

export const regionmanagecreate = data => ({ type: 'regionmanagecreate', data }); //Region management create

export const delete_region = item_id => ({ type: 'delete_region', item_id }); //Region delete

export const regionsingle = data => ({ type: 'regionsingle', data }); //get single Region details 

export const regionmanageupdate = (data, id) => ({ type: 'regionmanageupdate', data, id }); //Region update details 

export const region_search = (data) => ({ type: 'region_search', data }); //Region search details 

//Region Management Ends Here

//Territory Management Starts Here
export const territorymanagementlist = data => ({ type: 'territorymanagementlist', data }); //territory management list

export const territorymanagecreate = data => ({ type: 'territorymanagecreate', data }); //Territory management create

export const delete_territory = item_id => ({ type: 'delete_territory', item_id }); //territory delete

export const territorysingle = data => ({ type: 'territorysingle', data }); //get single territory details 

export const territorymanageupdate = (data, id) => ({ type: 'territorymanageupdate', data, id }); //territory update details 

export const territory_search = (data) => ({ type: 'territory_search', data }); //territory search details 
//Territory Management Ends Here

//Beats Management Starts Here
export const beatsmanagementlist = data => ({ type: 'beatsmanagementlist', data }); //beats management list

export const beatsmanagecreate = data => ({ type: 'beatsmanagecreate', data }); //beats management create

export const delete_beats = item_id => ({ type: 'delete_beats', item_id }); //beats delete

export const beatssingle = data => ({ type: 'beatssingle', data }); //get single Beats details 

export const beatsmanageupdate = (data, id) => ({ type: 'beatsmanageupdate', data, id }); //Beats update details 

export const beat_search = (data) => ({ type: 'beat_search', data }); //beat search details 
//Beats Management Ends Here

// Brands Management Starts Here
export const brandmanagementlist = data => ({ type: 'brandmanagementlist', data }); //brand management list

export const brandmanagecreate = data => ({ type: 'brandmanagecreate', data }); //brand management create

export const delete_brand = item_id => ({ type: 'delete_brand', item_id }); //brand delete

export const brandsingle = data => ({ type: 'brandsingle', data }); //get single Brand details 

export const brandmanageupdate = (data, id) => ({ type: 'brandmanageupdate', data, id }); //brand update details 

export const brand_search = (data) => ({ type: 'brand_search', data }); //Brand search details 
// Brands management Ends here 

// Admin User Start Here
export const adminuserlist = data => ({ type: 'adminuserlist', data }); //Admin User list

export const adminusercreate = data => ({ type: 'adminusercreate', data }); //Admin User create

export const delete_adminuser = item_id => ({ type: 'delete_adminuser', item_id }); //Admin User delete

export const adminusersingle = data => ({ type: 'adminusersingle', data }); //Admin User details 

export const adminuserupdate = (data, id) => ({ type: 'adminuserupdate', data, id }); //Admin User details 
// Admin User End Here

// Roles Start Here
export const roleslist = data => ({ type: 'roleslist', data }); //Roles list

export const rolescreate = data => ({ type: 'rolescreate', data }); //Roles create

export const delete_roles = item_id => ({ type: 'delete_roles', item_id }); //Roles delete

export const rolessingle = data => ({ type: 'rolessingle', data }); //Roles details 

export const rolesupdate = (data, id) => ({ type: 'rolesupdate', data, id }); //Roles details 

export const rolenamelist = (data) => ({ type: 'rolenamelist', data }); //Roles name list 

export const roleactive = (data, id) => ({ type: 'roleactive', data, id }); //Roles active  


// Roles User End Here

//customer Management Starts Here
export const customermanagementlist = data => ({ type: 'customermanagementlist', data }); //customer management list

export const customermanagecreate = data => ({ type: 'customermanagecreate', data }); //customer management create

export const delete_customer = item_id => ({ type: 'delete_customer', item_id }); //customer delete

export const customersingle = data => ({ type: 'customersingle', data }); //get single customer details 

export const customermanageupdate = (data, id) => ({ type: 'customermanageupdate', data, id }); //Customer update details 

export const customerUpload = file => ({ type: 'customerUpload', file }); //customer import .

export const customerexport = data => ({ type: 'customerexport', data }); //customer export .

export const customer_search = (data) => ({ type: 'customer_search', data }); //customer search details 
//customer Manegement Ends Here 


//indirect Customer 
export const indirectcustomermanagementlist = data => ({ type: 'indirectcustomermanagementlist', data }); //customer management list

export const indirectcustomercreate = data => ({ type: 'indirectcustomercreate', data }); //Indirect Customer Create

export const delete_indirectcustomer = item_id => ({ type: 'delete_indirectcustomer', item_id }); //indirect customer delete

export const wholesalerSearch = data => ({ type: 'wholesalerSearch', data }); //Wholesaler list

export const indirectcustomersingle = data => ({ type: 'indirectcustomersingle', data }); //get single indirect customer details 

export const indirectcustomermanageupdate = (data, id) => ({ type: 'indirectcustomermanageupdate', data, id }); //Indirect Customer update details 

export const indirectcustomerUpload = file => ({ type: 'indirectcustomerUpload', file }); //indirect customer import .

export const indirectcustomerexport = data => ({ type: 'indirectcustomerexport', data }); //indirect customer export .



//product Management Starts Here
export const productmanagementlist = data => ({ type: 'productmanagementlist', data }); //Product management list

export const stockmanagementlist = data => ({ type: 'stockmanagementlist', data }); //Product management list

export const productmanagecreate = data => ({ type: 'productmanagecreate', data }); //Product management create

export const delete_product = item_id => ({ type: 'delete_product', item_id }); //Product delete

export const productsingle = data => ({ type: 'productsingle', data }); //get single product details .

export const productmanageupdate = (data, id) => ({ type: 'productmanageupdate', data, id }); //product update details 

export const productUpload = file => ({ type: 'productUpload', file }); //product upload .

export const productexport = data => ({ type: 'productexport', data }); //product export .
//product Management Ends Here 
export const companylist = data => ({ type: 'companylist', data }); //company list 


// stock 
export const stockUpload = file => ({ type: 'stockUpload', file }); //stock upload .

export const stockexport = data => ({ type: 'stockexport', data }); //stock export .


// stock history
export const stockHistory_list = data => ({ type: 'stockHistory_list', data }); // stock history list

export const stockHistory_get = data => ({ type: 'stockHistory_get', data }); // get single stock history.

//order management Starts here 
export const ordermanagementlist = data => ({ type: 'ordermanagementlist', data }); //order management list

export const orderSingle = data => ({ type: 'orderSingle', data }) //Single Order

export const orderNoList = data => ({ type: 'orderNoList', data }) // Order NO

export const orderUpdate = (data, id) => ({ type: 'orderUpdate', data, id }); //order update details 

export const orderReject = (id) => ({ type: 'orderReject', id }); //order reject details 

export const orderexport = data => ({ type: 'orderexport', data }); //order export .

export const multipleOrderExport = data => ({ type: 'multipleOrderExport', data }); //order multiple-export .

export const usersdashboard = data => ({ type: 'usersdashboard', data }); //user management list

export const product_top_10 = data => ({ type: 'product_top_10', data }); //dashboard product top 10

export const product_least_10 = data => ({ type: 'product_least_10', data }); //dashboard product least 10

export const customers_least_10 = data => ({ type: 'customers_least_10', data }); //dashboard customers least 10

export const customers_top_10 = data => ({ type: 'customers_top_10', data }); //dashboard customers least 10

export const customers_visit = data => ({ type: 'customers_visit', data }); //dashboard customers least 10

export const customers_visit_export = data => ({ type: 'customers_visit_export', data }); //dashboard customers least 10

export const orders_top_10 = data => ({ type: 'orders_top_10', data }); //dashboard orders top 10

export const orders_least_10 = data => ({ type: 'orders_least_10', data }); //dashboard orders top 10

export const routeOrdermanagementlist = data => ({ type: 'routeOrdermanagementlist', data }) // orders for route planning

export const routeOrdermanagementlist2 = data => ({ type: 'routeOrdermanagementlist2', data }) // orders for route planning

export const outstandingDays = id => ({ type: 'outstandingDays', id }) //order outstanding Days

export const delete_order = item_id => ({ type: 'delete_order', item_id }); //order delete

export const orderCreate = data => ({ type: 'orderCreate', data }); //order create

export const order_pdf = item_id => ({ type: 'order_pdf', item_id }); //order pdf

//order management ends here

// Get Profile
export const profilesingle = data => ({ type: 'profilesingle', data }); //get profile

// Updated Profile
export const updateprofile = data => ({ type: 'updateprofile', data }); //update profile

//change Password 
export const changePassword = data => ({ type: 'changePassword', data }); //change Password 

export const changeEmailConfig = data => ({ type: 'changeEmailConfig', data }); //change emailConfig 

//get company config 
export const companyConfigSingle = data => ({ type: 'companyConfigSingle', data }); //get company config

//update company config 
export const updatecompanyconfig = data => ({ type: 'updatecompanyconfig', data }); //update company config

export const generatesecretkey = data => ({ type: 'generatesecretkey', data }); //generate secret key

// address Dropdown 
// country 
export const country_search = (data) => ({ type: 'country_search', data }); //country search details 

//state
export const state_search = (data) => ({ type: 'state_search', data }); //state search details 

//district
export const district_search = (data) => ({ type: 'district_search', data }); //district search details 

//city
export const city_search = (data) => ({ type: 'city_search', data }); //city search details 


//Route Management  starts here 
//create 
export const routecreate = (data) => ({ type: 'routecreate', data }); //country search details 

export const routemanagementlist = data => ({ type: 'routemanagementlist', data }); //route management list

export const delete_route = item_id => ({ type: 'delete_route', item_id }); //delete delete

export const routesingle = data => ({ type: 'routesingle', data }); //get single product details .

export const routeupdate = (data, id) => ({ type: 'routeupdate', data, id }); //route management create

export const closeTour = (data, id) => ({ type: 'closeTour', data, id }) // tour closure
//Route Management Ends here 


//outstanding
export const outstandingUpload = data => ({ type: 'outstandingUpload', data }); //outstanding export .

export const outstandlist = data => ({ type: 'outstandlist', data }); //outstanding list .

export const outstanding_user_list = (data) => ({ type: 'outstanding_user_list', data }); //Outstanding User List 

// dispatch details
export const dispatchlist = data => ({ type: 'dispatchlist', data }); // dispatch list .

export const dispatchUpload = data => ({ type: 'dispatchUpload', data }); // disptach export .

export const dispatch_user_list = (data) => ({ type: 'dispatch_user_list', data }); // Dispatch User List 

export const dispatchStatus = (data) => ({ type: 'dispatchStatus', data }); // Dispatch Status


//Version Update
export const versionUpdate = (data, versionId) => ({ type: 'versionUpdate', data, versionId }); //app version update


//Rate Management 
//rate List
export const ratelist = data => ({ type: 'ratelist', data }); //rate list .

export const ratecreate = data => ({ type: 'ratecreate', data }); //rate create.

export const delete_rate = item_id => ({ type: 'delete_rate', item_id }); //rate delete 

export const ratesingle = data => ({ type: 'ratesingle', data }); //get single Rate details 

export const rateupdate = (data, id) => ({ type: 'rateupdate', data, id }); //rate update details 


//Sr ASm User Assignment 
//create Sr ASM user assignment 
export const srAsmUserAssign = data => ({ type: 'srAsmUserAssign', data }); //Sr-ASM Create 

export const srasmassignList = data => ({ type: 'srasmassignList', data }); //sr-ASM list .

export const delete_srAsmAssign = item_id => ({ type: 'delete_srAsmAssign', item_id }); //sr-ASM delete 

export const SrAsmsingle = data => ({ type: 'SrAsmsingle', data }) //sr-ASM single

export const srAsmUserAssignUpdate = (data, id) => ({ type: 'srAsmUserAssignUpdate', data, id }) //Sr ASM Assign Update

export const orderConfirm = (data, id) => ({ type: 'orderConfirm', data, id })  //Order Pack

export const unitCodeByShade = data => ({ type: 'unitCodeByShade', data }) //Unit Code by shade upload



//Scheme Management
export const schemecreate = data => ({ type: 'schemecreate', data }); //Scheme management create

export const schemelist = data => ({ type: 'schemelist', data }); //Scheme management list

export const delete_scheme = item_id => ({ type: 'delete_scheme', item_id }); //scheme delete 

export const schemesingle = data => ({ type: 'schemesingle', data }); //get single scheme details 

export const schemeupdate = (data, id) => ({ type: 'schemeupdate', data, id }); //Scheme update details 



//Transpoter 
export const transportercreate = data => ({ type: 'transportercreate', data }); //transporter management create

export const transporterlist = data => ({ type: 'transporterlist', data }); //transporter management List

export const delete_transporter = item_id => ({ type: 'delete_transporter', item_id }); //transporter delete 

export const transportersingle = data => ({ type: 'transportersingle', data }); //get single transporter details 

export const transporterupdate = (data, id) => ({ type: 'transporterupdate', data, id }); //transporter update details

export const transporterUpload = file => ({ type: 'transporterUpload', file }); //transporter upload .

export const transporter_search = data => ({ type: 'transporter_search', data }); //transporter Search .





//Retailer 

export const retailerlist = data => ({ type: 'retailerlist', data }); // Retailer list

export const retailerCreate = data => ({ type: 'retailerCreate', data }) // Retailer Create

export const delete_retailer = item_id => ({ type: 'delete_retailer', item_id }) //Retailer Delete

export const retailersingle = data => ({ type: 'retailersingle', data }); //get single retailer details 

export const retailermanageupdate = (data, id) => ({ type: 'retailermanageupdate', data, id }); //retailer update details 




//Category 

export const categorylist = data => ({ type: 'categorylist', data }); // Category list

export const categoryCreate = data => ({ type: 'categoryCreate', data }) // Category Create

export const delete_category = item_id => ({ type: 'delete_category', item_id }) //Category Delete

export const categorySingle = data => ({ type: 'categorySingle', data }); //get single Category details 

export const categoryUpdate = (data, id) => ({ type: 'categoryUpdate', data, id }); //Category update details 

export const category_search = data => ({ type: 'category_search', data }); //Category Search .



//Trend
export const trendlist = data => ({ type: 'trendlist', data }); // Trend list

export const trendCreate = data => ({ type: 'trendCreate', data }); // trend create

export const trendsingle = data => ({ type: 'trendsingle', data }); //get single trending details 

export const delete_trend = item_id => ({ type: 'delete_trend', item_id }) // Delete trend

export const trendUpdate = (data, id) => ({ type: 'trendUpdate', data, id }); //trend update details 





//E-Catelogue

export const cataloguelist = data => ({ type: 'cataloguelist', data })// catalogue list

export const catalogueCreate = data => ({ type: 'catalogueCreate', data }); // catalogue create

export const cataloguesingle = data => ({ type: 'cataloguesingle', data }); //get single catalogueing details 

export const delete_catalogue = item_id => ({ type: 'delete_catalogue', item_id }) // Delete Catalogue

export const catalogueupdate = (data, id) => ({ type: 'catalogueupdate', data, id }); //catalogue update details 



//Mis 
export const graphDataReq = data => ({ type: "graphDataReq", data }) //Mis Graph Data

export const graphDataReq2 = data => ({ type: "graphDataReq2", data }) //Mis Graph Data2

export const misReportTableReq = data => ({ type: "misReportTableReq", data }) //Mis Table

export const misReportTableReq2 = data => ({ type: "misReportTableReq2", data }) //Mis Table

export const misSettingSingle = data => ({ type: "misSettingSingle", data }) //Mis setting

export const misSettingUpdate = data => ({ type: "misSettingUpdate", data }) //Mis setting update

export const misPdfExport = data => ({ type: 'misPdfExport', data }) //mis Pdf export

//Company
export const companyCreate = data => ({ type: 'companyCreate', data }); // catalogue create

export const companySingle = data => ({ type: 'companySingle', data }); //company single

export const companyUpdate = (data, id) => ({ type: 'companyUpdate', data, id }); //company update details 




//discount
export const discountCreate = data => ({ type: "discountCreate", data }) //create

export const discountList = data => ({ type: 'discountList', data }) // list

export const dicountDelete = id => ({ type: "dicountDelete", id }) // delete 

export const discountSingle = id => ({ type: "discountSingle", id }) //single

export const discountUpdate = (data, id) => ({ type: "discountUpdate", data, id }) //update



//agency
export const agencyCreate = data => ({ type: "agencyCreate", data }) //create

export const agencyList = data => ({ type: 'agencyList', data })//list

export const delete_agency = id => ({ type: "delete_agency", id }) // delete 

export const agencySingle = id => ({ type: "agencySingle", id }) //single

export const agencyUpdate = (data, id) => ({ type: "agencyUpdate", data, id }) //update

export const agencyUpload = file => ({ type: 'agencyUpload', file }); //agency upload .



//Expense Catagory
export const expenseCatagoryCreate = data => ({ type: "expenseCatagoryCreate", data }) // create

export const expenseCatagoryList = data => ({ type: 'expenseCatagoryList', data }) //list

export const delete_ExpenseCatagory = id => ({ type: "delete_ExpenseCatagory", id }) // delete 

export const singleExpenseCategory = id => ({ type: 'singleExpenseCategory', id }) // Single

export const updateExpenseCategory = (data, id) => ({ type: 'updateExpenseCategory', data, id }) //Update

export const expenseCategorySearch = (data) => ({ type: 'expenseCategorySearch', data }) //Search

//Expense 
export const expenseApprovalList = (data) => ({ type: 'expenseApprovalList', data }) //expense Approval List

export const approvalRequest = (data, id) => ({ type: 'approvalRequest', data, id }) // expense approval request

export const expense_export = data => ({ type: 'expense_export', data }); //expense export.


//Advance 
export const advanceList = data => ({ type: 'advanceList', data })//advance List

export const advanceAction = (data, id) => ({ type: 'advanceAction', data, id }) // advance action

export const advanceManyAction = data => ({ type: 'advanceManyAction', data }) //Action on many

export const advance_export = data => ({ type: 'advance_export', data }); //advance export.


//Touring voucher
export const touringVoucherList = data => ({ type: 'touringVoucherList', data }) // touring voucher

export const touringCSV = data => ({ type: 'touringCSV', data })//touring CSV

export const logsList = data => ({ type: 'logsList', data })

//Commission Range starts here
export const commissionRange = data => ({ type: 'commissionRange', data }); //commission range list

export const commissionRangeCreate = data => ({ type: 'commissionRangeCreate', data }); //commission range create

export const commissionRangeDelete = item_id => ({ type: 'commissionRangeDelete', item_id }); //commission range delete 

export const commissionRangeSingle = data => ({ type: 'commissionRangeSingle', data }); //commission range single get details 

export const commissionRangeUpdate = (data, id) => ({ type: 'commissionRangeUpdate', data, id }); //commission range update details 

//country starts
export const country_list = data => ({ type: 'country_list', data }); // country list

export const country_create = data => ({ type: 'country_create', data }); // country create

export const country_delete = item_id => ({ type: 'country_delete', item_id }); // country delete 

export const country_get = data => ({ type: 'country_get', data }); // country get 

export const country_update = (data, id) => ({ type: 'country_update', data, id }); // country update 
//country ends

//state starts
export const state_list = data => ({ type: 'state_list', data }); // state list

export const state_create = data => ({ type: 'state_create', data }); // state create

export const state_delete = item_id => ({ type: 'state_delete', item_id }); // state delete 

export const state_get = data => ({ type: 'state_get', data }); // state get 

export const state_update = (data, id) => ({ type: 'state_update', data, id }); // state update 
//state ends

//city starts
export const city_list = data => ({ type: 'city_list', data }); // city list

export const city_create = data => ({ type: 'city_create', data }); // city create

export const city_delete = item_id => ({ type: 'city_delete', item_id }); // city delete 

export const city_get = data => ({ type: 'city_get', data }); // city get 

export const city_update = (data, id) => ({ type: 'city_update', data, id }); // city update 

//city ends

// payment module start here

export const paymentlist = data => ({ type: 'paymentlist', data }); // Payment management list

export const delete_payment = item_id => ({ type: 'delete_payment', item_id }); // Payment management delete

export const status_payment = (item_id, status) => ({ type: 'status_payment', item_id, status }); // Payment management approve

export const paymentSingle = id => ({ type: 'paymentSingle', id }); // payment single get details

export const partyNamesList = data => ({ type: 'partynamelist', data }); // partyNames get

export const paymentExport = data => ({ type: 'paymentExport', data }); //Payment export .


// payment module ends here

// Feedback module start here

export const feedbacklist = data => ({ type: 'feedbacklist', data }); // feedback management list

export const delete_feedback = item_id => ({ type: 'delete_feedback', item_id }); // feedback management delete

export const status_feedback = (item_id, status) => ({ type: 'status_feedback', item_id, status }); // feedback management approve

export const feedbackSingle = id => ({ type: 'feedbackSingle', id }); // feedback single get details

export const feedbackExport = data => ({ type: 'feedbackExport', data }); //feedback export .


// Feedback module ends here

// options module starts here

export const versionGet = data => ({ type: 'versionGet', data });

export const versionUpdateCall = data => ({ type: 'versionUpdateCall', data });

//options module ends here

//Order Template Management starts here
export const orderTemplate_list = data => ({ type: 'orderTemplate_list', data }); //Order Template management list

export const orderTemplate_create = data => ({ type: 'orderTemplate_create', data }); //Order Template management create

export const orderTemplate_delete = item_id => ({ type: 'orderTemplate_delete', item_id }); //Order Template delete 

export const orderTemplate_get = data => ({ type: 'orderTemplate_get', data }); //get Order Template details 

export const orderTemplate_update = (data, id) => ({ type: 'orderTemplate_update', data, id }); //Order Template update details 

export const orderTemplate_export = (data) => ({ type: "orderTemplate_export", data }); // order template export.

export const orderTemplate_upload = file => ({ type: 'orderTemplate_upload', file }); // order template upload.

export const orderTemplate_refresh = data => ({ type: 'orderTemplate_refresh', data }); // Order Template management list refresh

// Order Template Management ends here